import React from 'react'
import {PageProps, Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const OtherActivities = (props: PageProps) => (
  <Layout>
    <SEO title="OVERIGE ACTIVITEITEN" />
    <h5>Culturele evenementen: Sinterklaas & Koningsdag</h5>
    <p>
      Vanuit de Vereniging voor Nederlandse Taal en Cultuur Sofia wordt Sinterklaas en Koningsdag georganiseerd. Sinterklaas is in regel op de tweede
      zaterdag in december en Koningsdag op de zaterdag rond 27 april. Deze evenementen zijn voor de gehele Nederlandse en Vlaamse gemeenschap in
      Bulgarije. Heeft u interesse om samen met uw kind(eren) aan te sluiten of wilt u meer informatie, stuur een e-mail aan{' '}
      <a className="usefulLinks" href="mailto:culturalevents@nederlandseschool.bg">
        culturalevents@nederlandseschool.bg
      </a>
      .
    </p>

    <h5>CNaVT - Certificaat Nederlands als Vreemde Taal</h5>
    <p>
      De school is gecertificeerd om de CNAVT- examens af te nemen. Deze examens worden jaarlijks in mei gehouden. Voor meer informatie over de
      examens kunt u terecht op{' '}
      <a className="usefulLinks" href="www.cnavt.org" target="_blank">
        www.cnavt.org
      </a>
      . Voor meer informatie kunt u contact opnemen via het e-mailadres{' '}
      <a className="usefulLinks" href="mailto:welkom@nederlandseschool.bg">
        welkom@nederlandseschool.bg
      </a>
      .
    </p>

    <h5>Meer informatie</h5>
    <p>
      Updates over het schooljaar en over de culturele evenementen kunt u vinden op onze Facebookpagina{' '}
      <a className="usefulLinks" href="https://www.facebook.com/Nederlandse.School.in.Sofia.Bulgarije" target="_blank">
        Nederlandse School in Bulgarije
      </a>
      . Op onze Facebookpagina staan ook impressies van onze lessen en activiteiten op school.
    </p>
  </Layout>
)

export default OtherActivities
